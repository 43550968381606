<template>
  <div class="allGoods">
    <div
      style="padding-bottom: 10px; background: #fff; width: 190px"
      class="goods-item"
      @click="toPush()"
    >
      <el-image
        style="
          width: 188px;
          height: 188px;
          text-align: center;
          line-height: 240px;
        "
        :src="data.image"
        fit="fit"
      >
        <svg
          slot="error"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 54 61"
          fill="#e5e4e4"
          style="width: 50px; height: 50px"
        >
          <path
            d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"
          /></svg
      ></el-image>
      <div
        style="
          padding: 4px 10px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.87);
          height: 36px;
        "
      >
        {{ data.title }}
      </div>
      <div style="padding: 2px 10px">
        <el-tag size="mini" effect="plain" style="margin-right: 4px"
          >local Seller</el-tag
        >
        <el-tag size="mini" effect="plain" style="margin-right: 4px"
          >ShopMlalay</el-tag
        >
      </div>
      <div style="font-size: 12px; margin-top: 10px; padding: 0 10px">
        <div style="color: #ee4d2d">
          RM<span style="font-size: 16px">{{ data.price }}</span>
        </div>
        <div style="color: rgba(0, 0, 0, 0.54); display: flex; margin-top: 4px">
          <el-rate v-model="value2" disabled> </el-rate>
          <span style="margin-left: 10px">售 {{ data.sales }}</span>
        </div>
      </div>

      <div class="goods-float" @click.stop="toSimilar">找相似</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeGoods",
  props: ["data"],
  data() {
    return {
      value2: 5,
    };
  },
  methods: {
    toPush() {
      this.$parent.toGoodsInfo(this.$props.data.id);
    },
    toSimilar() {
      console.log(123);
    },
  },
};
</script>
<style lang="less">
.allGoods {
  width: 190px;
  //   height: 292px;
  padding: 0 5px;
  margin-right: 10px;
  margin-bottom: 15px;
  .goods-item {
    position: relative;
    border: 1px solid #fff;
  }
  .goods-item:hover {
    border: 1px solid #ee4d2d;
    cursor: pointer;
    transform: translateY(-2px);
    transition: all 300ms ease;
    z-index: 1;
  }

  .goods-float {
    position: absolute;
    bottom: -32px;
    color: #fff;
    z-index: 99;
    background: #ee4d2d;
    width: 190px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    text-align: center;
    display: none;
    left: -1px;
  }
  .goods-item:hover .goods-float {
    display: block;
    cursor: pointer;
  }
  .el-rate__icon {
    font-size: 12px;
    margin-right: -1px;
  }
}
</style>