<template>
  <div class="allGoodsA">
    <div class="main-card">
      <div class="top">
        <div class="all">AII</div>
        <div class="line"></div>
      </div>
      <div class="goods-list" v-if="httpShow">
        <div v-for="item in list.list" :key="item.id">
          <AllGoods :data="item"></AllGoods>
        </div>
      </div>
      <div v-else style="text-align: center; line-height: 200px">
        <img style="width: 70px" :src="lodingImg" alt="" />
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <el-pagination
          background
          :page-size="pageSize"
          :current-page="page"
          layout="prev, pager, next"
          :total="list.total"
          @current-change="getList"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { allGoods } from "@/api/home.js";
import AllGoods from "@/components/goods/AllGoods";
export default {
  components: {
    AllGoods,
  },
  data() {
    return {
      list: {
        total: 0,
        list: [],
      },
      page: 1,
      pageSize: 48,
      httpShow: false,
      lodingImg: require("@/assets/img/app/http.gif"),
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    getList(page) {
      this.httpShow = false;
  
      this.page = page;
      allGoods({
        page: this.page,
        page_size: this.pageSize,
      }).then((res) => {
        if (res.code == 1) {
          this.httpShow = true;
    
          this.list = res.data;
        }
      });
    },
        toGoodsInfo(id){
     this.$router.push({ path: "goodsInfo", query: { goodsId: id } });
    },
  },
};
</script>
<style lang="less" scoped>
.allGoodsA {
  background: #f5f5f5;
  padding: 40px;
  .top {
    position: relative;
    display: flex;
    justify-content: center;
    .all {
      width: 64px;
      height: 56px;
      background: #ee4d2d;
      border-radius: 8px;
      text-align: center;
      line-height: 56px;
      color: #fff;
      font-size: 20px;
      z-index: 1;
    }
    .line {
      position: absolute;
      border-top: 1px dotted rgba(0, 0, 0, 0.26);
      width: 100%;
      left: 0;
      top: 50%;
      margin: 0;
    }
  }
  .goods-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
}
</style>